import React from "react";
import Layout from "../../components/layout";
import ProjectComponent from "../../components/projects/projectComponent";

import {graphql, useStaticQuery} from "gatsby";

const ProjectPangaia = () => {
    const data = useStaticQuery(graphql`
        query {
            banner: file(relativePath: {eq: "projects/pangaia/pangaia-banner.jpg"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
            picture: file(relativePath: {eq: "projects/pangaia/pangaia-picture.jpg"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);

    const projectData = {
        project: "pangaia",
        website: "https://thepangaia.com/",
        banner: data.banner.childImageSharp.gatsbyImageData,
        stages: {
            about: true,
            design: false,
            tech: true,
            result: true,
        },
        colors: [],
        font: "",
        icons: [],
        picture: data.picture.childImageSharp.gatsbyImageData,
        googlePlay: "",
        appStore: "",
    };

    return (
        <Layout>
            <ProjectComponent projectData={projectData} />
        </Layout>
    );
};

export default ProjectPangaia;
